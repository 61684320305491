import React from "react";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { PageOuter } from "../components/PageOuter";
import Spacer from "../components/Spacer";
import { ArrowLink } from "../components/ArrowLink";

const NotFoundPage = (): JSX.Element => {
  const title = "404 Page Not Found";
  return (
    <Layout>
      <SEO title={title} />
      <PageOuter title={title}>
        <h3 tabIndex={0}>Page not found</h3>
        <Spacer height={2} />
        <ArrowLink arrowLeft to="/" ariaLabel="Clickable link to the home page">
          Home
        </ArrowLink>
      </PageOuter>
    </Layout>
  );
};

export default NotFoundPage;
